<p-toast [breakpoints]="{'920px': {width: '70%', right: '0', left: '0'}}"></p-toast>

<div class="">

  <p-menubar [baseZIndex]="10100" [model]="items">
    <ng-template pTemplate="start">
      <img src="../../assets/wasp-sting-svgrepo-com-ffff33.svg" height="50" style="margin-right: 1em;">
    </ng-template>
    <ng-template pTemplate="item" let-item let-root="root">
        <a pRipple class="flex align-items-center p-menuitem-link" style="margin-right: 1em; ">
            <span [class]="item.icon"></span>
            <span class="ml-2">{{item.label}}</span>
        </a>
    </ng-template>
  </p-menubar>

  <app-bar-eye></app-bar-eye>
  <app-delete [element]=""></app-delete>

  <div class="row">
    <div class="col-md-1"></div>
    <div [hidden]="showScan == false" class="col-md-10">
        <ngx-scanner-qrcode #action="scanner"></ngx-scanner-qrcode>

        <!-- data  -->
        <span>{{ action.data.value  }}</span><!-- value -->
        <span>{{ action.data  }}</span><!-- async -->
        
        <!-- Loading -->
         @if(action.isLoading){⌛ Loading...}
        <!--<p *ngIf="action.isLoading">⌛ Loading...</p>-->
        
        <!-- start -->
        <button (click)="action.isStart ? action.stop() : action.start()">{{action.isStart ? 'Stop' : 'Start'}}</button>
      <!--<button class="btn btn-default btn-lg"  type="button" (click)="startBarcodeScannerOverlay()">
      <img style="width: 26px" src="../../../assets/icons8-barcode-reader-96.png"/>Scan barcode
    </button>
    <ngx-barcode-scanner [(value)]="barcodeValue" [codes]="['code_128', 'ean', 'upc', 'upc_e', 'ean_8']" 
    [errorThreshold]="0.1" (valueChange)="onValueChanges($event)" (exception)="onError($event)"></ngx-barcode-scanner>
  -->
    <!--
    <barcode-scanner-livestream-overlay
      [type]="['ean', 'code_128', 'ean_8']"
      (valueChanges)="onValueChanges($event)"
      (started)="onStarted($event)"
    ></barcode-scanner-livestream-overlay>
    -->
  </div>
  <div class="col-md-1"></div>
</div>

<p-dialog header="Scan BarCode" (onHide)="onBCClose($event)" [(visible)]="displayBarcode" [breakpoints]="{'960px': '50vw', '640px': '50vw'}"
  [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header"  style="background: rgba(0,0,0,.03) !important">
    Barcode
    <!--<div class="row" width="100%" title="Click + Hold to drag"><img src="../../assets/drag.svg"></div>-->
  </ng-template>
  <ng-template pTemplate="content">
    <p>
      <label>{{ barcodeValue }}</label>
    </p>
    <p>
      <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="" (click)="onSubmit($event)"><i class="pi pi-check"></i></button>&nbsp;&nbsp;
      <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="" (click)="onClearScan($event)"><i class="pi pi-times"></i></button>
    </p>
  </ng-template>
</p-dialog>
<!--
<div class="barcode-value" [hidden]="!barcodeValue">
  <p>
    <label>{{ barcodeValue }}</label>
  </p>
  <p>
    <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="Submit" (click)="onSubmit($event)"></button>&nbsp;&nbsp;
    <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="Clear" (click)="onClearScan($event)"></button>
  </p>
</div>-->

<!--
<div class="row">
  <div class="col-md-6">
    <div class="card">
      <h5>Upload template</h5>
      <p-fileUpload name="songs[]" (onUpload)="onUpload($event)" multiple="multiple" accept="image/*,audio/*">
        <ng-template pTemplate="toolbar"></ng-template>
        <ng-template pTemplate="content">
          <ul *ngIf="uploadedFiles.length">
            <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
          </ul>
        </ng-template>
      </p-fileUpload>
    </div>
  </div>
  <div class="col-md-6"></div>
</div>
-->
@if (gridView) {
  <p-dataView #dv [value]="musics" filterBy="Name" layout="grid" [loading]="dataload">
    <ng-template pTemplate="header" style="background-color: #333300">
      <span class="p-input-icon-left mb-2 md:mb-0">
        <i class="pi pi-search"></i>
        <input #Search type="search" pInputText placeholder="Search by Name" (input)="dv.filter(Search.value)">
      </span>
    </ng-template>

    <!--<ng-container *NgIf="!dataload">-->
    <ng-template let-music pTemplate="gridItem">
      <div class="p-col-12 p-md-3">
        <div class="product-grid-item card">
          <div class="card-header">
            <div class="row" style="margin-top: 0rem; height: 33%;">
              <div class="col-sm-12" style="text-align: right; font-family: yearone;padding-top: 5px;">
                <span style="color: #ffff33;" class="product-price"><label>GENRE:</label>&nbsp;{{music.Genre}}</span>
              </div>
            </div>
            <div class="row" style="margin-top: 0rem; height: 65%;">
              <!--<div class="col-sm-3" style="text-align: right;"><img src="../../assets/user-hexagonffff33.svg" style="width: 50%;height: 50%;color: #ffff33" /></div>-->
              <div class="col-sm-12" style="color: #ffff33; padding-left: 15%;padding-top: .5em;font-family: yearone;">
                <span class="product-price" title="music owner"><img src="../../assets/user-hexagonffff33.svg" style="color: #ffff33" />OWNER:&nbsp;&nbsp;{{music.Owner}}</span>
              </div>
            </div>
            <!--<div class="col">
            <span style="color: #ffff33;" class="product-price" title="music owner"><img src="../../assets/user-hexagonffff33.svg" style="color: #ffff33" />&nbsp;<label >{{music.Owner}}</label></span>
          </div>
          <div class="col">
            <span style="color: #ffff33;" class="product-price"><img src="../../assets/Discogs_record_icon-ffff33.svg" style="color: #ffff33" />&nbsp;{{music.Genre}}</span>
          </div>-->
        </div>
        <!--
        <div class="row">
          <div class="col-md-12" style="padding-left: 1rem; border-bottom: 1px solid lightgrey">
            <span class="ml-auto">
              <button pButton pRipple type="button" icon="pi pi-user-edit" class="p-button-outlined p-button-rounded"></button>&nbsp;&nbsp;
              <button pButton pRipple type="button" icon="pi pi-arrow-right-arrow-left" class="p-button-outlined p-button-rounded"></button>&nbsp;&nbsp;
              <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-outlined p-button-rounded"></button>
            </span>
          </div>
        </div>
        -->
        <div class="row" style="border-left: 1px solid #666600; border-right: 1px solid #666600;">
          <div class="col-md-2">
            <button pButton title="Edit album" type="button" (click)="albumEdit(music)" icon="pi pi-user-edit" class="p-button-outlined p-button-rounded" style="color: #ffff33; margin: .25rem 0 .25rem .25rem"></button>
            <!--<button title="Edit album" style="margin: .25rem 0 .25rem .25rem">
            <p-image src="../../assets/userFFFF33.svg" width="26"></p-image>
          </button>-->
          <button pButton title="Compare to Discog" type="button" (click)="albumCompare(music)" icon="pi pi-arrow-right-arrow-left" class="p-button-outlined p-button-rounded" style="color: #ffff33; margin: .25rem 0 .25rem .25rem"></button>
          <button pButton title="Delete album" type="button" (click)="albumDelete(music)" icon="pi pi-trash" class="p-button-outlined p-button-rounded" style="color: #ffff33; margin: .25rem 0 .25rem .25rem"></button>
        </div>
        <div class="col-md-10">
          <div class="row">
            <div class="col-md-12">
              <div title="Artist name" style="color: #ffff33" class="product-name">{{music.Name}}</div>
            </div>
            <div class="col-md-12">
              <div title="Title" style="color: #ffff33" class="product-description">{{music.Title}}</div>
            </div>
            <div class="col-md-12" style="border-top:1px solid #333300; border-left:1px solid #333300;">
              <!--<button type="button" title="Edit album" (click)="albumEdit(music)" style="vertical-align: top;padding: 0px 5px !important; margin: 1em 0;" class="btn btn-default btn-xs"><fa-icon [icon]="faEdit"></fa-icon></button>-->
              <p-image src="{{music.ImageId}}"></p-image>
              <!--<p-rating [ngModel]="music.Rating" [readonly]="true" [cancel]="false"></p-rating>-->
            </div>
          </div>
        </div>
      </div>
      <div class="product-grid-item-bottom card-footer row" style="border: 1px solid #666600">
        <div class="col-sm-12" style="padding-bottom: 3px;"><fa-icon style="color: #ffff33; padding-right:4px" title="barcode" [icon]="faBarcode"></fa-icon><span style="color: #ffff33; padding-left:5px" title="barcode" class="product-price notched">&nbsp;{{music.BarCode}}</span></div>
        <div class="col-sm-12"><fa-icon style="color: #ffff33; padding-right:8px" title="music label" [icon]="faReceipt"></fa-icon><span style="color: #ffff33; padding-left:5px" title="music label" class="notched">&nbsp;{{music.Label}}</span></div>
        <!--<span><img src="../../assets/hexNhex.svg" style="width: 52px" /></span>
        <span><img src="../../assets/Hexffff33corner.svg" style="width: 52px" /></span>
        <span style="color: #ffff33; font-size: 36px;">&#x2B22;</span>-->
      </div>
    </div>
    <div class="hr"></div>
  </div>
  </ng-template>
  <!--</ng-container>-->
</p-dataView>
}

@if (listView) {
  <p-table [value]="musics" #dt stripedRows styleClass="p-datatable-striped" [resizableColumns]="true" columnResizeMode="expand" [loading]="dataload" [globalFilterFields]="['Name']" [scrollable]="true" >
    <ng-template #caption>
      <div class="card">
        <p-toolbar>
          <ng-template #start>
              <p-iconfield iconPosition="left">
                  <p-inputicon styleClass="pi pi-search" />
                  <input
                  pInputText
                  type="text"
                  (input)="dt.filterGlobal($event.returnValue, 'contains')"
                  placeholder="Search keyword"
              />
              </p-iconfield>
          </ng-template>
      </p-toolbar>
      </div>
  </ng-template>
    <!--<ng-template pTemplate="caption" style="background-color: #333300">
      <p-toolbar>
        <ng-template #start>
            <p-iconfield iconPosition="left">
                <p-inputicon styleClass="pi pi-search" />
                <input pInputText type="text" (input)="dt.filterGlobal($event.returnValue, 'contains')" placeholder="Search keyword" />
            </p-iconfield>
        </ng-template>
      </p-toolbar>

      <div class="flex">
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt.filterGlobal($event.returnValue, 'contains')" placeholder="Search keyword" />
        </span>
      </div>
    
    </ng-template>-->
    <!--
    <ng-template pTemplate="caption" style="background-color: #333300">
      <span class="p-input-icon-left mb-2 md:mb-0">
        <i class="pi pi-search"></i>
        <input #Search type="search" pInputText placeholder="Search by Name" (input)="dv.filter(Search.value)">
      </span>
    </ng-template>
    -->
    <!--<ng-container *NgIf="!dataload">-->
      <ng-template pTemplate="header">
        <tr>
          <th>Action</th>
          <th>Image</th>
          <th>BarCode</th>
          <th>Name / Title</th>
        </tr>
      </ng-template>
    <!--</ng-container>-->
      <ng-template pTemplate="body" let-music>
        <tr>
          <td><p-button icon="pi pi-user-edit" (click)="albumEdit(music)" />&nbsp;<button pButton (click)="albumCompare(music)" icon="pi pi-arrow-right-arrow-left" class="p-button-outlined p-button-rounded" style="color: #ffff33; margin: .25rem 0 .25rem .25rem"></button><button pButton title="Delete album" type="button" (click)="albumDelete(music)" icon="pi pi-trash" class="p-button-outlined p-button-rounded" style="color: #ffff33; margin: .25rem 0 .25rem .25rem"></button></td>
          <td><img [src]="music.ImageId" width="52" class="shadow-4" /></td>
          <td>{{music.BarCode}}</td>
          <td>{{music.Name}} / {{music.Title}}</td>
          
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="5">No customers found.</td>
        </tr>
    </ng-template>
    
  </p-table>
  
}

<p-dialog [(visible)]="displayAlbumEdit" [modal]="true" [position]="'topleft'"
header="Edit Album" 
  [draggable]="true" [resizable]="true" 
  [style]="{width: '75vw'}"  
  [baseZIndex]="10002">

  <ng-template pTemplate="header" style="color: #ffff33">
    <div class="row">
      <div class="col-md-12"><img src="../../assets/wasp-sting-svgrepo-com-ffff33.svg" height="40" class="mr-2">Album Hive Edit</div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="row" style="margin-top: .5rem; border: 1px solid #ffff33">
      <div class="col-md-12" style="margin: 3px;">
        <span>
          <input type="text" pInputText style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['Name']"/>
        </span>
      </div>
      <div class="col-md-12" style="margin: 3px;">
        <span>
          <input type="text" pInputText style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['Title']" />
        </span>
      </div>
      <div class="col-md-12" style="margin: 3px;">
        <span>
          <input type="text" pInputText style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['BarCode']" />
        </span>
      </div>
      <div class="col-md-12" style="margin: 3px;">
        <span>
          <input type="text" pInputText style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['ImageId']"/>
        </span>
      </div>
      <div class="col-md-12" style="margin: 3px;">
        <span>
          <img [src]="updateData['ImageId']" style="width: 80% !important" class="shadow-4" />
        </span>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="" (click)="onEditSave($event)"><i class="pi pi-check"></i></button>&nbsp;&nbsp;
  </ng-template>
</p-dialog>


<p-dialog header="Album Discog Compare" [(visible)]="displayAlbumCompare" (onHide)="hideAlbumCompare()"
  size="50vw" [breakpoints]="{'960px': '80vw', '640px': '80vw', '390px': '85vw'}">
  <ng-template pTemplate="header" style="color: #ffff33">
    <div class="row">
      <div class="col-md-12"><img src="../../assets/wasp-sting-svgrepo-com-ffff33.svg" height="40" class="mr-2">Album Discog Compare</div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="row" style="margin-top: .5rem; border: 1px solid #ffff33">
      <div class="col-md-12" style="margin: 3px;">
        <span>
          <img src="../../assets/wasp-sting-svgrepo-com-ffff33.svg" style="width: 18px; padding-right: 5px;"/>
          <input type="text" style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['Name']" pInputText/>
        </span>
      </div>
      <div class="col-md-12" style="margin: 3px;">
        <span>
          <img src="../../assets/Discogs_record_icon-ffff33.svg" style="width: 18px; padding-right: 5px;"/>
          <input type="text" style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['Title']" pInputText/>
        </span>
      </div>
    </div>
    <p style="margin-bottom: 5px !important"></p>
    <p-fieldset legend="BarCode">
      <div class="row" style="margin-top: .5rem;">
        <div class="col-md-12" style="margin: 3px;">
          <span>
            <img src="../../assets/wasp-sting-svgrepo-com-ffff33.svg" style="width: 18px; padding-right: 5px;"/>
            <input type="text" pInputText style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['BarCode']"/>
          </span>
        </div>
        <div class="col-md-12" style="margin: 3px;">
          <span>
            <img src="../../assets/Discogs_record_icon-ffff33.svg" style="width: 18px; padding-right: 5px;"/>
            <label style="border: 1px solid #ffff33; width: 90% !important">{{discogArtist.BarCode}}</label>
          </span>
        </div>
      </div>
    </p-fieldset>
    <p style="margin-bottom: 5px !important"></p>
    <p-fieldset legend="Image">
      <div class="row">
        <div class="col-md-12" style="margin: 3px;">
          <span>
            <img src="../../assets/wasp-sting-svgrepo-com-ffff33.svg" style="width: 18px; padding-right: 5px;"/>
            <input type="text" pInputText style="color: #ffff33; border:1px solid #ffff33; background-color: #333300; padding: 0px !important; width: 90%" [(ngModel)]="updateData['ImageId']"/>
            <i (click)="updateData['ImageId']=discogArtist.ImageId" class="pi pi-chevron-circle-up" style="padding-left: 3px;"></i>
          </span>
        </div>
        <div class="col-md-12" style="margin: 3px;">
          <span>
            <img src="../../assets/Discogs_record_icon-ffff33.svg" style="width: 18px; padding-right: 5px;"/>
            <img [src]="discogArtist.ImageId" style="width: 80% !important" class="shadow-4" />
            <!--<label>{{discogArtist.ImageId}}</label>-->
          </span>
        </div>
      </div>
    </p-fieldset>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="" (click)="onReEdit($event)"><i class="pi pi-refresh"></i></button>&nbsp;&nbsp;
    <button pButton type="button" class="p-button-raised p-button-sm mt-1" label="" (click)="onEditSave($event)"><i class="pi pi-check"></i></button>&nbsp;&nbsp;
  </ng-template>
</p-dialog>
</div>


