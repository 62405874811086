import {} from '../types';
import { AfterContentInit, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { OverlayService } from '../components/overlay/overlay.module';
import { BarEyeComponent } from '../../app/components/bar-eye/bar-eye.component';
import { APIService } from '../services/api.service';
import {TreeModule} from 'primeng/tree';
import { DataViewModule } from 'primeng/dataview';
import {TreeNode} from 'primeng/api';
import { TableModule } from 'primeng/table';
import { FieldsetModule } from 'primeng/fieldset';
import { Dialog } from 'primeng/dialog';
import { ToolbarModule } from 'primeng/toolbar';
import { InputTextModule } from 'primeng/inputtext';
import { IconField } from 'primeng/iconfield';
import { InputIcon } from 'primeng/inputicon';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { MessageService, PrimeIcons } from 'primeng/api';
import  *  as _ from 'lodash';
import { Music } from '../music';
import {MenubarModule} from 'primeng/menubar';
import {MenuItem} from 'primeng/api';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEdit, faPlusCircle, faSpinner, faUser, faStreetView, faTruckRampBox, faTruck,  faKeyboard, faRecordVinyl, faBarcode, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { DeleteComponent } from '../components/delete/delete.component';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';

const user = {
  accountId: '5e82164c5228010c1b68d92b'
};
const dateRange = {
  from: '2021-09-13',
  to: '2021-10-08'
};

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [FormsModule, NgxScannerQrcodeModule, InputTextModule, DeleteComponent, BarEyeComponent, ImageModule, FontAwesomeModule,
        ToastModule, ConfirmPopupModule, TableModule, TreeModule, DataViewModule, ButtonModule, MenubarModule, Dialog, ToolbarModule, 
        IconField, InputIcon, FieldsetModule, CommonModule],
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    providers: [APIService, OverlayService, MessageService, DatePipe]
})
export class HomeComponent implements OnInit {
  barcodeValue: string = "";
  displayBarcode: boolean = false;
  @ViewChild(BarEyeComponent, {static : true}) child!: BarEyeComponent;
  @ViewChild(DeleteComponent, {static : true})  deletechild!: DeleteComponent;
  color = 'primary';
  value = 50;
  files: TreeNode[] = [];
  musics: Music[] = [];
  items: MenuItem[] | undefined;
  discogArtist: any;
  spinner: boolean = false;

  barcodeDialog: boolean = false;
  gridView: boolean = false;
  showScan: boolean = false;
  listView: boolean = false;
  displayAlbumCompare: boolean = false;
  displayAlbumEdit: boolean = false;
  displayAlbumEdit2: boolean = false;
  faReceipt = faReceipt;
  faBarcode = faBarcode;
  faSpinner = faSpinner;
  faEdit = faEdit;
  faUser = faUser;
  faStreetView = faStreetView;
  faTruckRampBox = faTruckRampBox;
  faTruck = faTruck;
  faPlusCircle = faPlusCircle;
  faKeyboard = faKeyboard;
  faRecordVinyl = faRecordVinyl;
  uploadedFiles: any[] = [];
  customerLoads: any[] = [];
  updateData: any = {};
  dataload: boolean = false;

  displayResponsive: boolean = true;
  userName: string = "";
  public loading: boolean = false;
  public employeeNumber: string = "";

  constructor(private apiService: APIService, private messageService: MessageService, private datepipe: DatePipe) {
    this.customerLoads = [
      {door: '1', customerName: 'USF / SAN FRAN', load: '111', stagingLane: '1'},
      {door: '2', customerName: 'CHEETAH TECH', load: '222', stagingLane: '1'},
      {door: '3', customerName: 'PFS / NORTHERN', load: '333', stagingLane: '2'},
      {door: '55', customerName: 'COSTCO DEPOT / TRYACY', load: '444', stagingLane: '2'}
      //{door: '5', customerName: 'SYSCO Central', load: '555', stagingLane: '3'},
      //{door: '55', customerName: 'MCLane', load: '666', stagingLane: '3'}
    ];
    //this.child = {};
    //this.deleteChild = {};
  }

  ngAfterViewInit() {
    
  }

  startBarcodeScannerOverlay(): void {
    //if( this.barcodeScannerOverlay )
      //this.barcodeScannerOverlay.show();
    //console.log("is it defined ", this.barcodeScannerOverlay == null ? "false": "yes");
  }

  onValueChanges(result: any): void {
    this.barcodeValue = result.codeResult.code;
  }

  onStarted(event: boolean): void {
    console.log('started', event);
  }

  hideAlbumEdit(){
    console.log('hidden', this.updateData);
    this.displayAlbumEdit = false;
    this.updateData = {};
  }

  hideAlbumCompare(){
    console.log('hidden', this.updateData);
    this.displayAlbumCompare = false;
    this.updateData = {};
  }

  onReEdit(evt: any){
    console.log('album re-edit', evt);
    this.albumCompare(this.updateData);
  }

  async onEditSave(evt: any){
    console.log('save album edit', evt);
    await this.apiService.saveMusic(this.updateData)
        .then(response => {
            console.log(response);
            this.displayAlbumCompare = false;
            this.displayAlbumEdit = false;
            //don't do this, make a refresh do this.
            //this.loadMusic();
            if( this.discogArtist )
              this.discogArtist = {};
            this.updateData = {};          
        }).catch((error: any) => {
            console.log(error);
        }
    );
  }

  async albumEdit(music: any){
    this.updateData = music;
    this.displayAlbumEdit = true;
    //this.displayAlbumEdit2 = true;
  }

  albumDelete(music: any){
    this.deletechild.updateData = music;
    this.deletechild.displayDelete = true;
  }

  async albumCompare(music: any){
    console.log('started', music);
    this.updateData = music;
    this.discogArtist = {};
    await this.apiService.getDiscogByTitleArtist(music.Title, music.Name)
    .then(response => {
          console.log('getDiscogByTitleArtist', JSON.stringify(response));
          if( response && response.results.length > 0 ){
            this.discogArtist = {BarCode: response.results[0].barcode[0], 
              Catno: response.results[0].catno,
              Name: music.Name,
              Title: music.Title,
              ImageId: response.results[0].cover_image};
          }
          this.loading = false;
    }).catch((error: any) => {
      console.log("Error :", error);
      this.messageService.add({severity: 'error', sticky: false, summary: 'get Discog Artist' , detail: 'Could not find Discog artist'});
    });

    this.displayAlbumCompare = true;
  }

  async loadMusic(){
    this.spinner = true;
    await this.apiService.getMusic()
    .subscribe(
      (data: any) => {
          this.musics = data;
          this.spinner = false;
          this.dataload = true;
      },
      error => {
          this.spinner = false;
          if (error.status !== 404) {
            this.messageService.add({severity:'error', sticky: false, summary:'Hive Load Music Error', detail:error.message});
          }
      }
    );
  }

  /*
  getWorkLogs(){
    console.log("Get work logs for user");
    const worklogs = tempo.worklogs.getForUser(user.accountId, dateRange)
    .then(worklogs => {
      console.log(worklogs.results);
    })
    .catch(err => {
      console.log(err);
    })
  }
  */

  getJSON(){

    this.apiService.getJsonFile()
      .subscribe( (data: any) => {
        console.log("get");
      },
      error => {
        this.messageService.add({severity: 'error', sticky: false, summary: 'Load JSON Error' , detail: error.message});
      }
    );
    console.log("test");
  }

  scanBarcode(){
    console.log('scanBarcode');
    this.startBarcodeScannerOverlay();
    this.showScan = true;
    this.child.displayScan = false;
  }

  onError(error: any){
    console.error(error);
    this.messageService.add({severity: 'error', sticky: false, summary: 'Barcode scan error' , detail: error.message});    
  }

  onSubmit(evt: any){
    console.log('submit Barcode ', this.barcodeValue);
    this.child.artistList = true;
    this.child.artist = this.barcodeValue;
    this.child.btnKeyboard = true;
    this.child.displayScan = true;
    this.child.selectedBarCatValue = 'bar'
    this.barcodeValue = '';
    this.showScan = false;
    this.displayBarcode = false;
    //this.barcodeScannerOverlay.hide();
  }
  onBCClose(evt: any){
    console.log('close Barcode ', this.barcodeValue);
    this.barcodeValue = '';
    this.showScan = false;
    //this.barcodeScannerOverlay.hide();
  }

  onClearScan(evt: any){
    console.log('clear Barcode ', this.barcodeValue);
    this.barcodeValue = '';
    this.showScan = false;
    //this.barcodeScannerOverlay.hide();
  }

  enterBarcode(){
    console.log('enterBarcode');
    this.child.displayScan = true;
    this.child.btnKeyboard = true;
    //this.barcodeDialog = true;
  }
  getGridView(){
    console.log('getGridView');
    this.gridView = !this.gridView;
    this.listView = false;
    this.dataload = false;
  }
  getListView(){
    console.log('getListView');
    this.listView = !this.listView;
    this.gridView = false;
    this.dataload = false;
  }

  async ngOnInit() {
    this.barcodeDialog = false;
    this.listView = false;
    this.gridView = false;
    this.loading = true;
    this.items = [
      {
          label: 'Music',
          icon: 'vinyl',
          items: [
              { label: 'Scan Barcode',
                icon: 'scanner',
                class: 'mySubItem',
                command: () => this.scanBarcode()
              },
              {label: 'Enter Barcode',
                icon: 'pi pi-fw pi-key',
                command: () => this.enterBarcode()
              },
              {label: 'Grid List',
              icon: 'grid',
                command: () => this.getGridView()},
              {label: 'Table List',
                icon: 'pi pi-fw pi-list',
                command: () => this.getListView()}
          ]
      },
      {
        label: 'Movies',
        icon: 'movie',
        items: [
            {label: 'Delete', icon: 'pi pi-fw pi-trash'},
            {label: 'Refresh', icon: 'pi pi-fw pi-refresh'}
        ]
      },
      {
        label: 'Books',
        icon: 'pi pi-fw pi-book',
        items: [
            {label: 'Delete', icon: 'pi pi-fw pi-trash'},
            {label: 'Refresh', icon: 'pi pi-fw pi-refresh'}
        ]
      }
  ];
    
    //this.getJSON();
    //this.getWorkLogs();
    this.loadMusic();

  }

}

