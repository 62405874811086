import { Input, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { APIService } from '../../services/api.service';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

@Component({
    selector: 'app-delete',
    standalone: true,
    imports: [FormsModule, ToastModule, ConfirmPopupModule, DialogModule, ButtonModule, InputTextModule],
    templateUrl: './delete.component.html',
    styleUrls: ['./delete.component.scss'],
    providers: [ConfirmationService, MessageService]
})
export class DeleteComponent implements OnInit {

  public displayDelete: boolean = false;
  @Input() element: any;
  public updateData: any;

  constructor(private apiService: APIService, private messageService: MessageService, private confirmationService: ConfirmationService) { 
    this.displayDelete  = false;
    this.updateData = "";
  }

  ngOnInit(): void {
  }

  hideDelete(){
    console.log('hidden');
    this.displayDelete = false;
    this.updateData = {};
  }

  onDelete(evt: any){
    this.confirmationService.confirm({
      target: evt.target,
      message: 'Are You Sure You Want To Delete?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
          console.log("You are deleting ", this.updateData);
          this.apiService.saveMusic(this.updateData)
                .then(response => {
                  console.log(response);
                  
                }).catch((error: any) => {
                  console.log(error);
                }
            );
          this.messageService.add({severity:'info', summary:'Confirmed', detail:'Deleted'});
      },
      reject: () => {
          console.log("You are rejecting ", this.updateData);
          this.messageService.add({severity:'info', summary:'Rejected', detail:'Rejected'});
      }
  });
  }

}

